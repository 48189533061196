import * as React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

import {
  Hr,
  Titles,
  TitlesWrapper,
  Content,
  Ul,
  Li,
  Bullet,
} from './ContentStyles';

const isBrowser = () => typeof window !== "undefined"

export const Skills = () => {

  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  );

  const options = {
    responsive: true,

    plugins: {
      legend: {
        display: false,
        position: 'top'
      },
      title: {
        display: true,
        text: 'Level of confidence on a scale of 1 to 5'
      },
    },
  };
  
  const labels =  [
    'Gatsby',
    'React',
    'Bootstrap',
    'Javascript Vanilla',
    'Typescript + ES6',
    'CSS',
    'Python (OOP)',
    'MongoDB',
    'JimuJS',
    'PHP',
    'Java',
    'C#'
  ];
  
  const data = {
    labels,
    datasets: [
            {
              axis: 'y',
              data: [4, 4.5, 4, 5, 4, 5, 5, 3.5, 4.5, 3, 2, 4],
              fill: false,
              backgroundColor: ['rgba(54, 162, 235, 0.6)'],
              borderColor: ['rgb(54, 162, 235)'],
              borderWidth: 1,
            },
          ]
  };

  let width = isBrowser() && window.innerWidth;
  if (width > 1000) {
    options.indexAxis= 'x';
    options.maintainAspectRatio = true;
  } else {
    options.indexAxis = 'y';
    options.maintainAspectRatio = false;
  }

  useWindowSize();

  return (
    <>
      <Titles md={3}>
        <TitlesWrapper>
          <h2 style={{textDecoration: 'underline'}}>Trending Experience</h2>
          <h4>sorted based on relevance & knowledge</h4>
        </TitlesWrapper>
        <Hr />
      </Titles>
      <Content md={6}>
        <Ul>
          <Li>
            <Bullet>💡</Bullet>
            <div>
              Most recent experience is reflected from working on this website
            </div>
          </Li>
          <Li>
            <Bar
              style={{ height: 'auto', width: 'auto' }}
              options={options}
              data={data} />
          </Li>
        </Ul>
      </Content>
    </>
  );
};

function useWindowSize() {
  const [windowSize, setWindowSize] = React.useState({
    width: undefined,
    height: undefined,
  });
  React.useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    isBrowser() && window.addEventListener("resize", handleResize);
    handleResize();
    return () => isBrowser() && window.removeEventListener("resize", handleResize);
  }, []);
  return windowSize;
}